<script>
import SelectorBlock from "@/components/v2/module/person/prefData/SelectorBlock.vue";
import {ApiService} from "@/util/ApiService";
import {API_METHODS, TYPE_ERRORS} from "@/constant";
import notify from "@/helpers/notify/notify";

export default {
  name: "EditPersonRoleContract",
  props: {
    person: {type: Object, default: () => {}}
  },
  components: {SelectorBlock},
  data() {
    return {
      isBlock: true,
      token:'',
      modeAdd: false,
      showTok: false,
      loadData: {
        personRoleContractId: 0,
      },
      canChangeRoleManuallyOnly: false
    };
  },
  computed: {
    paramsGetData() {
      return {PersonId: this.$route.params.PersonId};
    },
  },
  created() {
    this.getCurrentRole()
    this.canChangeRoleManuallyOnly = this.person?.changeRoleManuallyOnly;
  },
  methods: {
    setRoleId(val, isModeInit = false) {
      this.loadData.personRoleContractId = val.entityId;
    },

    async  getCurrentRole(){
      try {
        const params = {
          self: this,
          PersonId: this.$route.params.PersonId,
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        this.isBlock = true;
        const res = await ApiService(API_METHODS.GET_ROLE_PERSON_IN_CONTRACT, params);
        if(res.object) {
          this.loadData.personRoleContractId = res.object.personRoleContractId;
        }
        this.isBlock = false;
        this.load = false;
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
        this.isBlock = false;
      }
    },


    async  setRole(){
      const {
        personRoleContractId
      } = this.loadData;
      try {
        const params = {
          self: this,
          PersonId: this.$route.params.PersonId,
          saveData: {
            personRoleContractId
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.SET_ROLE_PERSON_IN_CONTRACT, params);
        this.showNotification(res)
        this.load = false;
        await this.getCurrentRole();
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },

    showNotification(res) {
      if (res.isValid)
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
      else
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
    },

    async customSearhc(search = null, id) {
      try {
        const params = {
          self: this,
          size: 1000,
          ContractId: this.$route.params.ContractId,
          search: search ? search : '',
          lang: this.$store.state.common.lang,
        };
        this.load = true;
        let res
        res = await ApiService(API_METHODS.GET_CONTRACT_LIST_ROLE, params);
        res.object = res.object ? res.object.map(item => {
          return {
            entityCode: null,
            entityId: item.personRoleContractId,
            entityName: item.corteosRoleName + " (" +item.corteosRoleId+")",
            languageId: 0
          }
        }) : []

        this.load = false;

        if(id){
          const find = res.object.find(item => item.entityId == id)
          return {
            object: find
          }
        }

        return res
      } catch (err) {
        this.load = false;
      }
      return [];
    },

    async setMode(val) {
      try {
        const params = {
          self: this,
          PersonId: this.$route.params.PersonId,
          saveData: {
            canChangeRoleManuallyOnly: Boolean(val)
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.SET_MODE_ACTIVE_ROLE_PERSON_IN_CONTRACT, params);
        this.showNotification(res)
        this.load = false;
        this.canChangeRoleManuallyOnly = val;
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-2">
      <label>&nbsp;</label>
     <h5>{{ $t("Управление ролями CORTEOS для персоны") }}</h5>  </div>
    <SelectorBlock
      classElement="blockOrg"
      :labelText="$t('Роль')"
      url="/RefEntity/DropDownList/Client"
      :isBlock="isBlock"
      @selectCode="setRoleId"
      labelSelect="entityName"
      :form="loadData"
      nameFiels="personRoleContractId"
      :use-custom-function-search="true"
      :customFunctionSearch="customSearhc"
    />
    <div class="col-2">
      <label>&nbsp;</label>
      <l-button
        type="success"
        wide
        block
        @click="setRole"
        :disabled="!loadData.personRoleContractId"
      >{{ $t("Установить роль") }}
      </l-button>
    </div>
    <div class="col-2" v-if="!canChangeRoleManuallyOnly">
      <label>&nbsp;</label>
      <l-button
        type="success"
        wide
        block
        @click="setMode(true)"
      >{{ $t("activeModerRole") }}
      </l-button>
    </div>
    <div class="col-2" v-if="canChangeRoleManuallyOnly">
      <label>&nbsp;</label>
      <l-button
        type="danger"
        wide
        block
        @click="setMode(false)"
      >{{ $t("deactiveModerRole") }}
      </l-button>
    </div>
  </div>
</template>

<style scoped>

</style>
