<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ShowPerson"
    routeNameCreate="CreatePerson"
    :keyRoute="'PersonId'"
    :backUrl="pathBack2"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :force-no-show-edit="true"
  >
    <template v-slot:block4>
      <edit-person-role-contract :person="loadData" v-if="loadData"/>
    </template>
  <template v-slot:block2>
        <person-data :loadDataIn="loadData" v-if="loadData"  :activeShowMenu="true" />
  </template>
  <template name="history" >
     <history-person
      v-if="!($route.name == 'CreatePerson')"
      :modals="modals"
      :toggle-modal="toggleModal"
      :closeHistory="closeHistory"
    />
  </template>
  </common-page-edit>
</template>
<script>

import { API_METHODS , FIELD_LANG_PERSON } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';
import PersonData from "./PersonData.vue";
import HistoryPerson from "../../../modules/history/HistoryPerson.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import EditPersonRoleContract from "@/components/v2/module/person/EditPersonRoleContract.vue";

export default {
  name: "EditPageDynamic",
  components: {
    EditPersonRoleContract,
    CommonPageEdit,
    PersonData,
    HistoryPerson
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "EntityDynamicShow",
      routeNameCreate: "CreatePageDynamic",

      pathBack: `/travelers`,
      pathBack2: "",
       modals: {
        history: false
      },
      apiNameGet: API_METHODS.GET_PERSON,
      apiNameCreate:API_METHODS.CREATE_PERSON,
      apiNameUpdate:API_METHODS.UPDATE_PERSON,
      loadData: null
    };
  },
  computed: {
    nameEnity(){
      return this.$route.params.clientPerson
    },



    title() {
      let text1 =   this.$t("Персона");
      let text2 =  this.$t("Создание персоны");
      let text3 = this.$t("Редактирование персоны");

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return text1
    },
   nameRoleEntity(){
         return NAME_OBJECT.attrEntity
   },
  },
  beforeRouteEnter(to, from, next) {
    let fromName = from ? from.name : null;
    next(vm => {
      if (fromName == "travelersPage") {
        vm.pathBack2 = vm.pathBack;
      } else {
        vm.pathBack2 = `/contract/${vm.$route.params.ContractId}/edit`
      }
    });
  },
  methods:{
    convertLoadData(res){
          let loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          let scheme = {configForm: []}

          scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_PERSON
          });

          loadData.dateBirth = this.$moment(
            loadData.dateBirth
          ).format("YYYY-MM-DD");
          loadData.ContractId = this.$route.params.ContractId;

          /**
           * Добавляем особые правила валидации для полей
           */
           scheme.configForm = scheme.configForm.map(item => {
            if (item.type === "ENTITY_LANG_BLOCK") {
              item.children = item.children.map(_item => {
                const fieldName = _item.fieldName;
                if (
                  fieldName === "lastName" ||
                  fieldName === "firstName" ||
                  fieldName === "middleName"
                ) {
                  return {
                    ..._item,
                    capitalize: true,
                    validateRu: {
                      ..._item.validationRule,
                      regex: "^[а-яА-ЯЁё' '\-]+$"
                    },
                    validateEn: {
                      ..._item.validationRule,
                      regex: "^[a-zA-Z' '\-]+$"
                    }
                  };
                }
                return _item;
              });
            }
            return item;
          });

          return {
            loadData,
            fieldInfoList,
            scheme
          }
    },
    setLoadData(loadData){
      this.loadData = loadData
    },
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },
    paramsSave() {
      return {
          id: this.$route.params.PersonId,
          ContractId: this.$route.params.ContractId
        }
    }
  }
};
</script>
