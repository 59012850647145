<template>
  <card v-if="$route.params.PersonId">
    <el-tabs :tab-position="tabPosition" v-model="nowTab" @before-leave="beforeLeave" @tab-click="beforeLeave">
      <el-tab-pane :label="$t('Документы')" name="doc">
        <PersonDocuments
          v-if="nowTab == 'doc'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('Бонусные карты')" name="card">
        <PersonBonusCard
          v-if="nowTab == 'card'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
       <el-tab-pane :label="$t('Контакты')" name="contact">
        <PersonContact
          v-if="nowTab == 'contact'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('Коды персоны')" name="personecodeValue">
        <PersonCodeValue
          v-if="nowTab == 'personecodeValue'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('Кредитные карты')" name="creditCard"  v-if="showMenu(nameObject.CreditCardPerson)">
        <CreditCardTable
          v-if="nowTab == 'creditCard'"
          :isPersonMode="true"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
     <el-tab-pane :label="$t('Предпочтения')" name="pref">
        <PersonPrefData
          v-if="nowTab == 'pref'"

        />
      </el-tab-pane>
      <el-tab-pane :label="$t('Виза')" name="visa">
        <PersonVisa
          v-if="nowTab == 'visa'"
          :hasReadOnlyInTable="loadData ? !loadData.readOnly : true"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('Ремарки Sabre')" name="sabreRemark">
        <SabreRemarkTable
          v-if="nowTab == 'sabreRemark'"
          :isPersonMode="true"

        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
import { Tabs, TabPane } from "element-ui";
import PersonDocuments from "../person/personDocument/PersonDocuments.vue";
import PersonBonusCard from "../person/bonusCard/PersonBonusCard.vue";
import PersonContact from "../person/contactPerson/PersonContact.vue";
import PersonPrefData from "./prefData/PersonPrefData";
import PersonCodeValue from "../personCodeValue/PersonCodeValue";

import CreditCardTable from "../../../modules/creditCard/CreditCardTable";
import PersonVisa from "./personVisa/PersonVisa";
import SabreRemarkTable from "../../../modules/sabreRemark/SabreRemarkTable";
const  SET_TAB_PERSON = "SET_TAB_PERSON"
import clearReactive from "../../../../helpers/clearReactive"
import { NAME_OBJECT, NAME_ACTION } from "../../../../RoleConstanans";
export default {
  name: "PersonData",
  props: {
    loadDataIn: { type: Object },
    activeShowMenu: { type: Boolean, default: false }
  },
  created() {
    this.loadData = clearReactive(this.loadDataIn);
  },
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    PersonDocuments,
    PersonBonusCard,
    PersonContact,
    PersonCodeValue,
    CreditCardTable,
    PersonPrefData,
    PersonVisa,
    SabreRemarkTable,
  },
  data() {
    return {
      loadData: null,
      tabPosition: "top",
      nameObject: NAME_OBJECT
    };
  },
  computed: {
    nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_PERSON, tab);
      },
      get() {
        return this.$store.state.person.tabnow;
      },
    },
  },
  methods: {
    beforeLeave(){
      this.$store.commit("CLEAR_TABLE_PAGINATOR", { delUrl: this.$route.path });
    },
    showMenu(name) {
      if (this.activeShowMenu) {
        let isActionAccess = this.$store.getters.isActionAccess;
        return isActionAccess(name, NAME_ACTION.isMenu, name, "showMenu");
      }
      return true;
    }
  }
};
</script>
